.products-section {
  background-size: cover;
  background-position: center;
  padding: 4rem 0;
  position: relative;
  background-color: #f4f4f4; // Легкий цвет фона для улучшения контраста

  .container {
    position: relative;
    z-index: 1;
  }

  .section-title {
    color: #333;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  .grid {
    gap: 1.5rem;
  }

  .product-item {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    .product-image {
      width: 100%;
      height: 100%; // Устанавливаем высоту в зависимости от контейнера
      object-fit: cover;
      border-radius: 12px;
      transition: transform 0.3s ease;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4); // Полупрозрачный черный фон
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      color: #fff;

      .overlay-title {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    &:hover .overlay {
      opacity: 1;
    }
  }
}

// Адаптивные стили
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .product-item {
    .product-image {
      height: 250px; // Высота изображения на средних экранах
    }
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.5rem;
  }

  .product-item {
    .product-image {
      height: 200px; // Высота изображения на маленьких экранах
    }
  }
}
