.SendPhoneNumber {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    padding: 5% 10%;

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .bg-overlay {
        position: relative;
        margin-top: 5%;
        width: 90%;
        max-width: 600px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        padding: 2rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        text-align: center;

        h1 {
            font-size: 1.8rem;
            margin-bottom: 1rem;
            color: #333;
        }

        p {
            font-size: 1rem;
            color: #666;
            margin-bottom: 1rem;
        }

        .form-container {
            background-color: rgba(255, 255, 255, 0.85);
            padding: 30px;
            border-radius: 12px;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

            p {
                font-size: 18px;
                color: #333;
                margin-bottom: 20px;
            }

            form {
                display: flex;
                flex-direction: column;

                input[type="text"],
                input[type="tel"],
                select,
                textarea {
                    width: 100%;
                    padding: 12px 16px;
                    margin: 10px 0;
                    font-size: 16px;
                    font-family: 'Arial', sans-serif;
                    color: #333;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    background-color: #f9f9f9;

                    &:hover,
                    &:focus {
                        border-color: #007bff;
                        background-color: #fff;
                        outline: none;
                    }

                    &::placeholder {
                        color: #888;
                    }
                }

                textarea {
                    resize: none;
                    min-height: 100px;
                }

                button[type="submit"] {
                    width: 100%;
                    padding: 14px 20px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-family: 'Arial', sans-serif;
                    color: #fff;
                    background-color: #007bff;
                    border: none;
                    border-radius: 8px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: #0056b3;
                    }

                    &:disabled {
                        background-color: #ccc;
                        cursor: not-allowed;
                    }
                }

                .error-message {
                    color: #e74c3c;
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .bg-overlay {
            width: 90%;
            padding: 1.5rem;
        }

        h1 {
            font-size: 1.6rem;
        }

        p {
            font-size: 0.9rem;
        }

        .form-container {
            padding: 20px;
        }

        input,
        select,
        textarea,
        button {
            font-size: 14px;
            padding: 10px;
        }
    }

    @media (max-width: 768px) {
        .SendPhoneNumber {
            padding-top: 20%;
        }

        .bg-overlay {
            margin-top: 20%;
            padding-top: 1%;
            width: 95%;
            padding: 1rem;
        }

        h1 {
            font-size: 1.4rem;
        }

        p {
            font-size: 0.8rem;
        }

        input,
        select,
        textarea,
        button {
            font-size: 12px;
            padding: 8px;
        }
    }

    @media (max-width: 480px) {
        .bg-overlay {
            width: 100%;
            padding: 0.8rem;
        }

        h1 {
            font-size: 1.2rem;
        }

        p {
            font-size: 0.7rem;
        }

        input,
        select,
        textarea,
        button {
            font-size: 10px;
            padding: 6px;
        }
    }
}
