.service-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .service-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .service-icon {
      font-size: 2.5rem;
      color: #3498db;
    }

    h3 {
      font-size: 1.5rem;
      color: #2c3e50;
      margin: 0;
      font-weight: 700;
    }

    .toggle-button {
      background: none;
      border: none;
      color: #3498db;
      font-size: 1rem;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: #2980b9;
      }
    }
  }

  .service-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 0;

    p {
      font-size: 1rem;
      color: #34495e;
      line-height: 1.6;
    }

    &.open {
      max-height: 500px; // Максимальная высота, чтобы показать всю информацию
      opacity: 1;
    }
  }
}
