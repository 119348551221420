.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background-color: rgba(255, 255, 255, .9);
    margin: 0;
    padding: 10px;
    

    #menu-bar {
        display: none;
        color: white;
        border: 1px solid white;
        font-size: 1.8rem;
        padding: .2rem .4rem;
        border-radius: .3rem;
        cursor: pointer;

        &:hover {
            color: black;
        }
    }

    .LeftSide {
        display: flex;
        align-items: center;
        gap: .2rem;



        .Logo {
            img {
                width: 80px;
                height: 120px;
                cursor: pointer;
                border-radius: 50%;
                transition: filter 0.3s ease;


                &:hover {
                    filter: brightness(0.9);
                }
            }
        }
    }

    nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;

        a {
            position: relative;
            display: block;
            text-transform: uppercase;
            margin: 20px 0;
            padding: 10px 20px;
            text-decoration: none;
            color: #262626;
            font-family: sans-serif;
            font-size: 18px;
            font-weight: 600;
            transition: .5s;
            z-index: 1;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-top: 2px solid #262626;
                border-bottom: 2px solid #262626;
                transform: scaleY(2);
                opacity: 0;
                transition: .3s;
            }

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #262626;
                transform: scale(0);
                opacity: 0;
                transition: .3s;
                z-index: -1;
            }

            &:hover {
                color: #fff;

                &:before {
                    transform: scaleY(1);
                    opacity: 1;
                }

                &:after {
                    transform: scaleY(1);
                    opacity: 1;
                }
            }
        }

        a.active {
            color: gray;
        }
    }
}

.RightSide {

    span {
        display: none;
    }

}

@media(max-width: 1024px) {
    .Header {
        background-color: rgba(22, 22, 23, .8);
        padding: 10px 5px;

        nav {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 0 40px 30px 40px;
            display: block;
            background-color: rgba(22, 22, 23, .8);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            transition: .5s ease;
            transform: none;

            &.active {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

            }

            a {
                color: rgba(255, 255, 255, .8);
                display: block;
                border-bottom: 1px solid white;
                margin: .5rem;
                padding: .5rem;
            }

            a.active {
                color: gray;

            }

            a:hover {
                background-color: rgba(255, 255, 255, .8);
                ;
            }

        }

        #menu-bar {
            display: block;
            color: white;
            font-size: 1.5rem;
            margin-left: 13px;
        }

        .LeftSide .Logo {

            position: absolute;
            left: 48%;
            transform: translateX(-50%);
            color: #fff;
            border-radius: 50%;

            img {
                width: 60px;
                background-color: rgba(255, 255, 255, .8);
                ;
                height: 60px;
            }
        }

        .NewLogo {
            display: none;
            position: absolute;
            left: 48%;
            transform: translateX(-50%);


            img {
                width: 80px;
                height: 80px;
                background-color: #fff;
            }
        }

        .RightSide {
            text-align: right;

            span {
                display: none;

            }

            .icons .fa-solid,
            .fas {
                color: rgba(255, 255, 255, .8);
            }

            .phonenumber {

                color: rgba(255, 255, 255, .8);
                margin-left: 5px;
                text-align: right;
            }

            .call-me {
                display: none;
            }

            .content {
                left: 35px;
                font-size: 10px;
                font-weight: 500;
                color: rgba(255, 255, 255, .8);
                text-align: right;
            }
        }
    }
}