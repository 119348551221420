.services {
  padding: 60px 20px;
  background-color: #f9f9f9;
  position: relative;
  z-index: 1;

  .services-header {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 10%;

    h1 {
      font-size: 2.5rem;
      color: #333;
      margin: 0;
    }

    p {
      font-size: 1.2rem;
      color: #666;
      margin-top: 10px;
      line-height: 1.6;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    .service-item {
      flex: 1 1 calc(33.333% - 40px);
      max-width: 300px;
      margin: 0 auto;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }

  @media (max-width: 768px) {
    .services-list {
      flex-direction: column;
      align-items: center;

      .service-item {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }
}

.service-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .service-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .service-icon {
      font-size: 3rem;
      color: #3498db;
      margin-right: 10px;
    }

    h3 {
      font-size: 1.75rem;
      color: #333;
      margin: 0;
    }
  }

  .service-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 0;

    &.open {
      max-height: 500px;
      opacity: 1;
    }
  }
}
