.social-links {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;

  .social-title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }

  .social-description {
    font-size: 1rem;
    color: #34495e;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;

    .social-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #f0f0f0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      color: #2c3e50;
      font-size: 1.5rem;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: #3498db;
        color: #fff;
        transform: scale(1.1);
      }
    }

    .whatsapp {
      background-color: #25d366;
      color: #fff;

      &:hover {
        background-color: #1ebea5;
      }
    }

    .telegram {
      background-color: #0088cc;
      color: #fff;

      &:hover {
        background-color: #007ab8;
      }
    }

    .instagram {
      background-color: #e4405f;
      color: #fff;

      &:hover {
        background-color: #c13584;
      }
    }
  }

  @media (max-width: 768px) {
    .social-title {
      font-size: 1.8rem;
    }

    .social-description {
      font-size: 0.9rem;
    }

    .social-icons {
      .social-icon {
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 480px) {
    .social-title {
      font-size: 1.5rem;
    }

    .social-description {
      font-size: 0.8rem;
    }

    .social-icons {
      .social-icon {
        width: 35px;
        height: 35px;
        font-size: 1rem;
      }
    }
  }
}
