.AboutCompany {
  .title {
    color: #333;
  }

  .description {
    color: #666;
    p {
      margin-bottom: 1rem;
    }
  }

  .advantages-section {
    background-color: #f4f4f4;
    
    .section-title {
      color: #333;
    }

    .advantage-item {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      text-align: center;
      
      .icon-container {
        background-color: #206777;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        
        .icon-image {
          color: #b8b4be; // Измените на выбранный цвет
          font-size: 3rem;
          
        }
      }
      
      h3 {
        color: #333;
        margin-top: 1rem;
        font-size: 1.25rem; // Настройте размер для лучшей читаемости
      }
      
      p {
        color: #555;
        margin-top: 0.5rem;
        font-size: 1rem; // Настройте размер для лучшей читаемости
      }
    }
  }

  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
    
    .description {
      font-size: 1rem;
    }
    
    .advantages-section {
      .advantage-item {
        .icon-container {
          width: 80px;
          height: 80px;
        }
        
        .icon-image {
          font-size: 2.5rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .title {
      font-size: 1.5rem;
    }
    
    .description {
      font-size: 0.9rem;
    }
    
    .advantages-section {
      .advantage-item {
        .icon-container {
          // width: 60px;
          // height: 60px;
        }
        
        .icon-image {
          font-size: 2rem;
         
        }
      }
    }
  }
}
