.contact-page {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;

  .contact-header {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 80px; // Отступ сверху для предотвращения перекрытия заголовка
    margin-top: 10%;

    h1 {
      font-size: 2.5rem;
      color: #2c3e50;
      margin-bottom: 10px;
      font-weight: 700;
    }

    .contact-subtitle {
      font-size: 1.2rem;
      color: #7f8c8d;
      font-weight: 300;
    }
  }

  .contact-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;

    .contact-item {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      h2 {
        font-size: 1.6rem;
        color: #34495e;
        margin-bottom: 10px;
        font-weight: 600;
      }

      p {
        font-size: 1.2rem;
        color: #2c3e50;
        margin: 0;
        line-height: 1.5;

        a {
          color: #3498db;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .map-container {
    text-align: center;
    margin-top: 40px;

    h2 {
      font-size: 2rem;
      color: #2c3e50;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .map {
      position: relative;
      width: 100%;
      height: auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 768px) {
    .contact-details {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 480px) {
    .contact-header {
      h1 {
        font-size: 2rem;
      }
      .contact-subtitle {
        font-size: 1rem;
      }
    }

    .contact-details {
      .contact-item {
        padding: 15px;
        
        h2 {
          font-size: 1.4rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }

    .map-container {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}
