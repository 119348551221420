.faq {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .faq-title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
    font-weight: 700;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }

  .faq-items {
    .faq-item {
      border-bottom: 1px solid #e0e0e0;
      padding: 15px 0;
      position: relative;
      transition: background-color 0.3s ease;

      &:last-of-type {
        border-bottom: none;
      }

      &.active {
        background-color: #f0f8ff;
      }

      .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        font-family: 'Roboto', sans-serif;

        h3 {
          font-size: 1.5rem;
          color: #34495e;
          margin: 0;
          font-weight: 600;
        }

        .faq-toggle {
          font-size: 1.5rem;
          color: #3498db;
          transition: transform 0.3s ease;
        }
      }

      .faq-answer {
        overflow: hidden;
        transition: max-height 0.3s ease, opacity 0.3s ease;
        max-height: 0;
        opacity: 0;
        visibility: hidden;

        &.active {
          max-height: 200px; /* Убедитесь, что это значение достаточно для содержимого */
          opacity: 1;
          visibility: visible;
        }

        p {
          margin: 0;

          a {
            color: #3498db;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .faq-title {
      font-size: 1.8rem;
    }

    .faq-item {
      padding: 10px 0;

      .faq-question {
        h3 {
          font-size: 1.3rem;
        }

        .faq-toggle {
          font-size: 1.2rem;
        }
      }

      .faq-answer {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 15px;

    .faq-title {
      font-size: 1.5rem;
    }

    .faq-item {
      padding: 8px 0;

      .faq-question {
        h3 {
          font-size: 1.2rem;
        }

        .faq-toggle {
          font-size: 1rem;
        }
      }

      .faq-answer {
        font-size: 0.9rem;
      }
    }
  }
}
