.services {
    padding: 5% 10%;
    background-color: #fafafa;
  
    .services-title {
      font-size: 2rem;
      color: #333;
      text-align: center;
      margin-bottom: 3rem;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .services-container {
      display: flex;
      justify-content: space-around;
      gap: 2rem;
  
      .service-item {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: translateY(-5px);
        }
  
        .service-icon {
          color: #555;
          font-size: 3rem;
          margin-bottom: 1rem;
        }
  
        .service-subtitle {
          font-size: 1.5rem;
          color: #444;
          margin-bottom: 1rem;
        }
  
        .service-description {
          font-size: 1rem;
          color: #666;
          line-height: 1.6;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 10% 5%;
  
      .services-title {
        font-size: 1.8rem;
      }
  
      .services-container {
        flex-direction: column;
        align-items: center;
      }
  
      .service-item {
        width: 100%;
        margin-bottom: 1.5rem;
        text-align: center;
      }
  
      .service-icon {
        font-size: 2.5rem;
      }
  
      .service-subtitle {
        font-size: 1.4rem;
      }
  
      .service-description {
        font-size: 0.95rem;
      }
    }
  }
  