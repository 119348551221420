@tailwind base;
@tailwind components;
@tailwind utilities;


// @import 'swiper/swiper-bundle.min.css'; // Основные стили Swiper
// @import 'swiper/modules/navigation/navigation.min.css'; // Стили для навигации
// @import 'swiper/modules/pagination/pagination.min.css'; // Стили для пагинации



// Импорт шрифта
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// Применение шрифта к body и другим элементам
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px; // Установите базовый размер шрифта
  line-height: 1.5; // Установите межстрочный интервал
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700; // Используйте жирный шрифт для заголовков
  margin: 0;
}

p,
a,
span,
li,
button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; // Обычный вес для основного текста
}

.Header {
  font-family: 'Roboto', sans-serif;
  // Ваши стили для Header
}



// Примеры для других компонентов
.Button {
  font-family: 'Roboto', sans-serif;
  font-weight: 500; // Средний вес для кнопок
}

.NavLink {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; // Обычный вес для навигационных ссылок
}

body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-decoration: none;
  font-family: 'roboto';

}


:root {
  --white: #fff;
  --black: #000;
  --white-light: rgba(255, 255, 255, .9);
  --light-gray: #A9A9A9;
  --darkgray: rgb(114, 109, 109);
  --phone-color: rgb(53, 55, 108);
  --gray: rgb(50, 44, 44);
  --blek: rgba(22, 22, 23, .8);
}

::selection {
  background-color: rgb(121, 135, 202);
  color: var(--white);
}

.HomeButton {

  width: 9em;
  height: 3em;
  border-radius: 30em;
  font-size: 15px;
  color: #fff;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5,
    -6px -6px 12px #ffffff;
  ;
  cursor: pointer;
}

.HomeButton::before {

  content: "";
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #0fd850 0%, #f9f047 100%);
  transition: .5s ease;
  display: block;
  z-index: -1;
}

.HomeButton:hover::before {

  width: 9em;

}

.ServiceButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }
}

