.footer {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 120px; // Увеличение высоты для размещения новых данных
  padding: 20px 50px;
  display: flex;
  flex-direction: column; // Убедитесь, что элементы располагаются в столбец
  align-items: center; // Центрирование содержимого

  .social-icon {
    display: flex;
    margin-bottom: 20px; // Отступ снизу для отделения от меню

    li {
      list-style: none;

      a {
        width: 60px;
        height: 60px;
        background-color: #fff;
        text-align: center;
        line-height: 60px;
        font-size: 30px;
        margin: 0 10px;
        display: block;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        border: 3px solid #fff;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f0f0f0; // Цвет фона при наведении
        }

        .icon {
          color: #262626;
        }
      }
    }
  }

  .menu {
    display: flex;
    margin-bottom: 20px; // Отступ снизу для отделения от информации

    .menu__item {
      margin: 0 15px; // Отступ между пунктами меню

      a {
        text-transform: uppercase;
        text-decoration: none;
        color: #262626;
        font-family: sans-serif;
        font-size: 18px;
        font-weight: 600;
        transition: color 0.3s;

        &:hover {
          color: #007BFF; // Цвет текста при наведении
        }
      }
    }
  }

  .footer-info {
    text-align: center; // Центрирование информации
    font-size: 14px; // Размер шрифта
    color: #666; // Цвет текста

    p {
      margin: 5px 0; // Отступ между параграфами
    }
  }

  .footer-text {
    color: #262626;
    text-align: center; // Центрирование текста
    margin-top: 15px; // Отступ сверху
  }

  // Медиа-запрос для мобильных устройств
  @media (max-width: 768px) {
    padding: 10px 20px; // Уменьшение отступов
    .footer-info {
      font-size: 12px; // Уменьшение размера шрифта
    }
  }
}
