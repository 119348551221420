.about-section {
    padding: 5% 10%;
    background-color: #f5f5f5;
  
    .about-title {
      font-size: 2.5rem;
      color: #333;
      text-align: center;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;
    }
  
    .icon-grid {
      display: flex;
      justify-content: space-around;
      gap: 2rem;
  
      .icon-card {
        background-color: #ffffff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: translateY(-5px);
        }
  
        .icon {
          color: #333;
          font-size: 3rem;
          margin-bottom: 1rem;
        }
  
        .subtitle {
          font-size: 1.5rem;
          color: #555;
          margin-bottom: 1rem;
        }
  
        .description {
          font-size: 1rem;
          color: #777;
          line-height: 1.6;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 10% 5%;
  
      .about-title {
        font-size: 2rem;
      }
  
      .icon-grid {
        flex-direction: column;
        align-items: center;
      }
  
      .icon-card {
        width: 90%;
        margin-bottom: 1.5rem;
      }
  
      .icon {
        font-size: 2.5rem;
      }
  
      .subtitle {
        font-size: 1.3rem;
      }
  
      .description {
        font-size: 0.95rem;
      }
    }
  }
  